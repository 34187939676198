import { sendMailAxios } from '@lib/axios';

interface Params {
    fullName: string;
    phoneNumber: string;
    companyName: string;
    email: string;
    website: string;
    comment: string;
}

interface Res {
    success: boolean;
    message: string;
}
export type SendMailRes = Res;
export type SendMailParams = Params;

export const sendMail = async (params: Params): Promise<Res> => {
    const res = await sendMailAxios.post(
        '/mailer/tarlan-payments-main-website',
        params
    );
    return res.data;
};
