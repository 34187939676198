import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    multiplier: number;
}

const initialState: InitialState = {
    multiplier: 0
};

export const {
    reducer: homeCalculatorReducer,
    actions: homeCalculatorActions
} = createSlice({
    name: 'homeCalculator',
    initialState,
    reducers: {
        setMultiplier(state, { payload }: PayloadAction<number>) {
            state.multiplier = payload;
        }
    }
});

export const incomes = [100000, 500000, 1000000, 5000000, 10000000];
