import { strapiAxios } from '@lib';
import {
    StrapiListItemFromRes,
    StrapiResponsiveRichTextEditorFromRes
} from '@sanch941/lib';

interface Res {
    data: {
        id: number;
        attributes: Partial<{
            createdAt: string;
            updatedAt: string;
            publishedAt: string;
            rich_welcome_description: StrapiResponsiveRichTextEditorFromRes;
            contact_us_title: string;
            team: {
                id: number;
                description: string;
                title: string;
                rich_description: StrapiResponsiveRichTextEditorFromRes;
            };
            dates: StrapiListItemFromRes[];
        }>;
    };
    meta: unknown;
}
export type AboutGetRes = Res;

export const aboutUsPageGet = async (lang: string): Promise<Res> => {
    const res = await strapiAxios.get('/api/about-us-page', {
        params: { locale: lang }
    });
    return res.data;
};
