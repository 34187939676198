import React from 'react';
import {
    Text,
    StrapiResponsiveRichTextEditorFromRes,
    TextProps
} from '@sanch941/lib';
import styled from 'styled-components';
import { getResponsiveRichEditorText } from './get-responsive-rich-editor-text';
import ReactMarkdown from 'react-markdown';

export const RichText = styled(Text).attrs<Props>(({ text }) => ({
    as: 'div',
    children: <ReactMarkdown children={getResponsiveRichEditorText(text)} />
}))<Props>`
    white-space: pre-wrap;
`;

interface Props extends TextProps {
    text: StrapiResponsiveRichTextEditorFromRes;
}
export type RichTextProps = Props;
