import * as Yup from 'yup';
import { apiCall } from '@apis/api';
import { SendMailRes, SendMailParams } from '@apis/send-mail';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    modal: boolean;
    sendMailLoading: 'idle' | 'pending' | 'fulfilled' | 'rejected';
}

interface Params {
    name: string;
    company: string;
    phone: string;
    email: string;
    user_comment: string;
    website: string;
}

const initialState: InitialState = {
    modal: false,
    sendMailLoading: 'idle'
};

export const initialValues = {
    name: '',
    company: '',
    phone: '',
    email: '',
    user_comment: '',
    website: '',
    income: ''
};

export const SendMailSchema = Yup.object().shape({
    name: Yup.string().required('Обязательное поле'),
    company: Yup.string().required('Обязательное поле'),
    phone: Yup.string().required('Обязательное поле'),
    email: Yup.string().required('Обязательное поле')
});

export const { reducer: requestFormReducer, actions: requestFormActions } =
    createSlice({
        name: 'requestForm',
        initialState,
        reducers: {
            setOpen(state, { payload }: PayloadAction<boolean>) {
                state.modal = payload;
            }
        },
        extraReducers: (builder) => {
            builder.addCase(sendMail.pending, (state) => {
                state.sendMailLoading = 'pending';
            });
            builder.addCase(sendMail.fulfilled, (state) => {
                state.sendMailLoading = 'fulfilled';
            });
            builder.addCase(sendMail.rejected, (state) => {
                state.sendMailLoading = 'rejected';
            });
        }
    });

export const sendMail = createAsyncThunk<SendMailRes, Params>(
    'requestForm/sendMail',
    async ({ name, company, phone, email, user_comment, website }) => {
        const params: SendMailParams = {
            fullName: name,
            phoneNumber: phone,
            companyName: company,
            email,
            website,
            comment: user_comment
        };

        const resData = await apiCall.sendMail(params);
        return resData;
    }
);
