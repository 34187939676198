import { strapiAxios } from '@lib';
import { StrapiListItemFromRes } from '@sanch941/lib';

interface Res {
    data: {
        id: number;
        attributes: {
            createdAt: string;
            updatedAt: string;
            publishedAt: string;
            title: string;
            categories: StrapiListItemFromRes[];
            questions: StrapiListItemFromRes[];
        };
    };
    meta: unknown;
}
export type FaqGetRes = Res;

export const faqGet = async (lang: string): Promise<Res> => {
    const res = await strapiAxios.get('/api/faq', {
        params: { locale: lang }
    });
    return res.data;
};
