import { homepageGet } from './homepage';
import { globalGet } from './global';
import { aboutUsPageGet } from './about-us-page';
import { faqGet } from './faq';
import { vacancyGet } from './vacancy';
import { sendMail } from './send-mail';

export const apiCall = {
    homepageGet,
    globalGet,
    aboutUsPageGet,
    faqGet,
    vacancyGet,
    sendMail
};
