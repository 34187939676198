import { createGlobalStyle } from 'styled-components';

import roboto400Woff2 from '@assets/fonts/Roboto/roboto-v29-latin_cyrillic-regular.woff2';
import roboto400Woff from '@assets/fonts/Roboto/roboto-v29-latin_cyrillic-regular.woff';
import roboto400Ttf from '@assets/fonts/Roboto/roboto-v29-latin_cyrillic-regular.ttf';

import roboto500Woff2 from '@assets/fonts/Roboto/roboto-v29-latin_cyrillic-500.woff2';
import roboto500Woff from '@assets/fonts/Roboto/roboto-v29-latin_cyrillic-500.woff';
import roboto500Ttf from '@assets/fonts/Roboto/roboto-v29-latin_cyrillic-500.ttf';

import roboto700Woff2 from '@assets/fonts/Roboto/roboto-v29-latin_cyrillic-700.woff2';
import roboto700Woff from '@assets/fonts/Roboto/roboto-v29-latin_cyrillic-700.woff';
import roboto700Ttf from '@assets/fonts/Roboto/roboto-v29-latin_cyrillic-700.ttf';

import roboto900Woff2 from '@assets/fonts/Roboto/roboto-v29-latin_cyrillic-900.woff2';
import roboto900Woff from '@assets/fonts/Roboto/roboto-v29-latin_cyrillic-900.woff';
import roboto900Ttf from '@assets/fonts/Roboto/roboto-v29-latin_cyrillic-900.ttf';

export const Fonts = createGlobalStyle`
    @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url(${roboto400Woff2}) format('woff2'), /* Super Modern Browsers */
        url(${roboto400Woff}) format('woff'), /* Modern Browsers */
        url(${roboto400Ttf}) format('truetype'), /* Safari, Android, iOS */
    }
    /* roboto-500 - latin_cyrillic */
    @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local(''),
        url(${roboto500Woff2}) format('woff2'), /* Super Modern Browsers */
        url(${roboto500Woff}) format('woff'), /* Modern Browsers */
        url(${roboto500Ttf}) format('truetype'), /* Safari, Android, iOS */
    }
    /* roboto-700 - latin_cyrillic */
    @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url(${roboto700Woff2}) format('woff2'), /* Super Modern Browsers */
        url(${roboto700Woff}) format('woff'), /* Modern Browsers */
        url(${roboto700Ttf}) format('truetype'), /* Safari, Android, iOS */
    }
    /* roboto-900 - latin_cyrillic */
    @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: local(''),
        url(${roboto900Woff2}) format('woff2'), /* Super Modern Browsers */
        url(${roboto900Woff}) format('woff'), /* Modern Browsers */
        url(${roboto900Ttf}) format('truetype'), /* Safari, Android, iOS */
}
`;
