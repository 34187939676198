import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    StrapiListItemFromRes,
    MappedStrapiImage,
    mapStrapiImage,
    StrapiResponsiveRichTextEditorFromRes
} from '@sanch941/lib';
import { apiCall } from '@apis/api';
import { VacancyGetRes, VacancyGetPosition } from '@apis/vacancy';
import { RootState } from '@store';

interface InitialState {
    title: string;
    rich_title: StrapiResponsiveRichTextEditorFromRes;
    ourVacanciesTitle: string;
    weOffer: {
        id: number;
        title: string;
        offers: StrapiListItemFromRes[];
        img: MappedStrapiImage;
    };
    positions: VacancyGetPosition[];
}

const initialState: InitialState = {
    title: null,
    rich_title: null,
    ourVacanciesTitle: null,
    weOffer: {
        id: null,
        title: null,
        offers: [],
        img: null
    },
    positions: []
};

export const { reducer: vacanciesReducer } = createSlice({
    name: 'vacancies',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            fetchCmsDatForVacancy.fulfilled,
            (state, { payload }) => {
                const vacancy = payload?.data?.attributes;

                state.ourVacanciesTitle = vacancy.our_vacancies_title;
                state.positions = vacancy.positions;
                state.rich_title = vacancy.rich_title;
                state.title = vacancy.title;
                state.weOffer = {
                    ...vacancy.we_offer,
                    img: mapStrapiImage(vacancy.we_offer.img)
                };
            }
        );
    }
});

export const fetchCmsDatForVacancy = createAsyncThunk<VacancyGetRes>(
    'vacancies/fetchCmsDatForVacancy',
    async (_, { getState }) => {
        const {
            initialization: { lang }
        } = getState() as RootState;

        const resData = await apiCall.vacancyGet(lang);
        return resData;
    }
);
