import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    StrapiListItemFromRes,
    StrapiResponsiveRichTextEditorFromRes
} from '@sanch941/lib';
import { apiCall } from '@apis/api';
import { AboutGetRes } from '@apis/about-us-page';
import { RootState } from '@store';

interface InitialState {
    contactUsTitle: string;
    richWelcomeDescription: StrapiResponsiveRichTextEditorFromRes;
    team: {
        id: number;
        description: string;
        title: string;
        rich_description: StrapiResponsiveRichTextEditorFromRes;
    };
    dates: StrapiListItemFromRes[];
}

const initialState: InitialState = {
    contactUsTitle: null,
    richWelcomeDescription: null,
    team: {
        id: null,
        description: null,
        title: null,
        rich_description: null
    },
    dates: []
};

export const { reducer: aboutReducer } = createSlice({
    name: 'about',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            fetchCmsDataForAbout.fulfilled,
            (state, { payload }) => {
                const about = payload?.data?.attributes || {};

                state.team = about.team;
                state.contactUsTitle = about.contact_us_title;
                state.dates = about.dates;
                state.richWelcomeDescription = about.rich_welcome_description;
            }
        );
    }
});

export const fetchCmsDataForAbout = createAsyncThunk<AboutGetRes>(
    'about/fetchCmsDataForAbout',
    async (_, { getState }) => {
        const {
            initialization: { lang }
        } = getState() as RootState;

        const resData = await apiCall.aboutUsPageGet(lang);
        return resData;
    }
);
