import { strapiAxios } from '@lib';
import {
    StrapiListItemFromRes,
    StrapiImageFromRes,
    StrapiResponsiveRichTextEditorFromRes
} from '@sanch941/lib';

interface Position {
    id: number;
    profession: string;
    rich_sections: StrapiListItemFromRes[];
    sections: {
        id: number;
        title: string;
        description: string;
    }[];
    skills: StrapiListItemFromRes[];
}
export type VacancyGetPosition = Position;

interface Res {
    data: {
        id: number;
        attributes: {
            title: string;
            rich_title: StrapiResponsiveRichTextEditorFromRes;
            createdAt: string;
            updatedAt: string;
            publishedAt: string;
            our_vacancies_title: string;
            we_offer: {
                id: number;
                title: string;
                offers: StrapiListItemFromRes[];
                img: StrapiImageFromRes;
            };
            positions: Position[];
        };
    };
    meta: unknown;
}
export type VacancyGetRes = Res;

export const vacancyGet = async (lang: string): Promise<Res> => {
    const res = await strapiAxios('/api/vacancy', {
        params: { locale: lang }
    });
    return res.data;
};
