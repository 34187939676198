export const navigationMenuList = [
    {
        to: '/about',
        text: 'О нас'
    },
    {
        to: '/faq',
        text: 'Помощь'
    },
    {
        to: '/vacancies',
        text: 'Вакансии'
    }
];
