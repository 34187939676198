import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { StrapiListItemFromRes } from '@sanch941/lib';
import { apiCall } from '@apis/api';
import { FaqGetRes } from '@apis/faq';
import { RootState } from '@store';

interface Question extends StrapiListItemFromRes {
    open?: boolean;
}

interface InitialState {
    categories: StrapiListItemFromRes[];
    questions: Question[];
    title: string;
    selectedMenuId: number;
}

const initialState: InitialState = {
    categories: [],
    questions: [],
    title: null,
    selectedMenuId: 0
};

interface ToggleFaqItemParams {
    id: number;
    open: boolean;
}

export const { reducer: faqReducer, actions: faqActions } = createSlice({
    name: 'faqQuestions',
    initialState,
    reducers: {
        toggleFaqItem(
            state,
            { payload: { id, open } }: PayloadAction<ToggleFaqItemParams>
        ) {
            const idx = state.questions.findIndex(
                (question) => question.id === id
            );

            if (open) {
                state.questions = state.questions.map((question) => ({
                    ...question,
                    open: false
                }));
            }

            state.questions[idx].open = open;
        },
        toggleMenuItem(state, { payload: menuItemId }: PayloadAction<number>) {
            if (state.selectedMenuId === menuItemId) {
                state.selectedMenuId = 0;
            } else {
                state.selectedMenuId = menuItemId;
            }
        },
        setQuestions(state, { payload }: PayloadAction<Question[]>) {
            state.questions = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCmsDataForFaq.fulfilled, (state, { payload }) => {
            const faq = payload?.data?.attributes;

            state.categories = faq.categories;
            state.questions = faq.questions;
            state.title = faq.title;
        });
    }
});

export const fetchCmsDataForFaq = createAsyncThunk<FaqGetRes>(
    'faq/fetchCmsDataForFaq',
    async (_, { getState }) => {
        const {
            initialization: { lang }
        } = getState() as RootState;

        const resData = await apiCall.faqGet(lang);
        return resData;
    }
);
