import { initializationReducer } from '@features/initialization/model';
import { sidebarReducer } from '@features/sidebar/model';
import { faqReducer } from '@page-templates/faq/model';
import { homeCalculatorReducer } from '@page-templates/home/templates/calculator/model';
import { homeWelcomeReducer } from '@page-templates/home/templates/welcome/model';
import { combineReducers } from 'redux';
import { homeReducer } from '@page-templates/home/model';
import { globalStrapiReducer } from './global-strapi';
import { aboutReducer } from '@page-templates/about/model';
import { vacanciesReducer } from '@page-templates/vacancies/model';
import { requestFormReducer } from '@features/request-form/model';

export const rootReducer = combineReducers({
    sidebar: sidebarReducer,
    faq: faqReducer,
    homeWelcome: homeWelcomeReducer,
    homeCalculator: homeCalculatorReducer,
    initialization: initializationReducer,
    home: homeReducer,
    globalStrapi: globalStrapiReducer,
    about: aboutReducer,
    vacancies: vacanciesReducer,
    requestForm: requestFormReducer
});

type rootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<rootReducerType>;
