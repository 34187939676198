import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall } from '@apis/api';
import { HomepageGetRes } from '@apis/homepage';
import {
    MappedStrapiImage,
    mapStrapiImage,
    MappedStrapiListItem,
    StrapiButton,
    StrapiResponsiveRichTextEditorFromRes
} from '@sanch941/lib';
import { LightListItem } from '@lib/types';
import { RootState } from '@store';

interface InitialState {
    hero: {
        title: StrapiResponsiveRichTextEditorFromRes;
        description: string;
        integrate_button: StrapiButton;
        details_button: StrapiButton;
        payment_form_img: MappedStrapiImage;
        typewriter_new: LightListItem[];
    };
    integration: {
        title: string;
        cards: MappedStrapiListItem[];
    };
    advantages: {
        cards: MappedStrapiListItem[];
    };
    calculator: {
        percent: string;
        description: string;
        rich_percent: StrapiResponsiveRichTextEditorFromRes;
        rich_description: StrapiResponsiveRichTextEditorFromRes;
        img: MappedStrapiImage;
    };
    how_it_works: {
        title: string;
        description: string;
        cards: MappedStrapiListItem[];
    };
    security: {
        title: string;
        description_1: string;
        description_2: string;
        description_3: string;
        description_4: string;
        description_5: string;
    };
    partners: {
        title: string;
        cards: MappedStrapiListItem[];
    };
}

const initialState: InitialState = {
    hero: {
        title: null,
        description: null,
        typewriter_new: [],
        integrate_button: {
            id: null,
            name: null
        },
        details_button: {
            id: null,
            name: null
        },
        payment_form_img: {
            mobileFilename: null,
            commonFilename: null
        }
    },
    integration: {
        title: null,
        cards: []
    },
    advantages: {
        cards: []
    },
    calculator: {
        percent: null,
        description: null,
        img: null,
        rich_description: null,
        rich_percent: null
    },
    how_it_works: {
        title: null,
        description: null,
        cards: []
    },
    security: {
        title: null,
        description_1: null,
        description_2: null,
        description_3: null,
        description_4: null,
        description_5: null
    },
    partners: {
        title: null,
        cards: []
    }
};

export const { reducer: homeReducer, actions: homeActions } = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCmsDataForHome.fulfilled, (state, { payload }) => {
            const {
                hero,
                integration,
                advantages,
                calculator,
                how_it_works,
                security,
                partners
            } = payload.data.attributes;

            if (hero) {
                state.hero = {
                    title: hero.title,
                    description: hero.description,
                    typewriter_new: hero.typewriter_new,
                    integrate_button: hero.integrate_button,
                    details_button: hero.details_button,
                    payment_form_img: mapStrapiImage(hero.payment_form_img)
                };
            }

            if (integration) {
                state.integration = {
                    title: integration.title,
                    cards: integration.cards.map((card) => ({
                        ...card,
                        img: mapStrapiImage(card.img)
                    }))
                };
            }

            if (advantages) {
                state.advantages = {
                    cards: advantages.cards.map((card) => ({
                        ...card,
                        img: mapStrapiImage(card.img)
                    }))
                };
            }

            if (calculator) {
                state.calculator = {
                    percent: calculator.percent,
                    description: calculator.description,
                    rich_description: calculator.rich_description,
                    rich_percent: calculator.rich_percent,
                    img: mapStrapiImage(calculator.img)
                };
            }

            if (how_it_works) {
                state.how_it_works = {
                    title: how_it_works.title,
                    description: how_it_works.description,
                    cards: how_it_works.cards.map((card) => ({
                        ...card,
                        img: mapStrapiImage(card.img)
                    }))
                };
            }

            if (security) {
                state.security = {
                    title: security.title,
                    description_1: security.description_1,
                    description_2: security.description_2,
                    description_3: security.description_3,
                    description_4: security.description_4,
                    description_5: security.description_5
                };
            }

            if (partners) {
                state.partners = {
                    title: partners.title,
                    cards: partners.cards.map((card) => ({
                        ...card,
                        img: mapStrapiImage(card.img)
                    }))
                };
            }
        });
    }
});

export const fetchCmsDataForHome = createAsyncThunk<HomepageGetRes>(
    'home/fetchCmsDataForHome',
    async (_, { getState }) => {
        const {
            initialization: { lang }
        } = getState() as RootState;

        const resData = await apiCall.homepageGet(lang);
        return resData;
    }
);
