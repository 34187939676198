import { strapiAxios } from '@lib';
import {
    StrapiImageFromRes,
    StrapiResponsiveRichTextEditorFromRes
} from '@sanch941/lib';

interface Res {
    data: {
        id: number;
        attributes: {
            createdAt: string;
            updatedAt: string;
            publishedAt: string;
            locale: string;
            footer: {
                id: number;
                rich_address: StrapiResponsiveRichTextEditorFromRes;
                rich_rules_text: StrapiResponsiveRichTextEditorFromRes;
                rich_public_offer_text: StrapiResponsiveRichTextEditorFromRes;
                rich_copyright: StrapiResponsiveRichTextEditorFromRes;
                common_questions_email: {
                    id: number;
                    title: string;
                    href: string;
                    link_text: string;
                };
                tech_support_email: {
                    id: number;
                    title: string;
                    href: string;
                    link_text: string;
                };
                logos_sprite: StrapiImageFromRes;
            };
            localizations: {
                data: unknown[];
            };
        };
    };
    meta: unknown;
}
export type GlobalGetRes = Res;

export const globalGet = async (lang: string): Promise<Res> => {
    const res = await strapiAxios.get('/api/global', {
        params: { locale: lang }
    });
    return res.data;
};
